*,
*::before,
*::after {
    box-sizing: border-box;
}

html, body{
    height:100%;
}

html {
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}


h6 {
    margin: 5px 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-white {
    color: #fff;
}

.menu-toolbar-btn {
    line-height: 68px;
}

.RouteDetailOfferAcceptanceDialog .dialog {
    width: 600px;
    max-width: 90%;
    height: 470px;
}

.uppercase {
    text-transform: uppercase;
}

ons-page img {
    max-width: 100%;
}

.m10, h5.m10, h6.m10 {
    margin: 10px;
}

.text-center {
    text-align: center;
}
